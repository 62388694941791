<template>
  <base-popup
    v-if="sex === Sex.Male"
    :show="modelValue"
    @submit="submit"
    @close="$emit('update:modelValue', false)"
  >
    <package-of-services
      cancel-button
      :show-title="false"
      static-btn
      @cancel="$emit('update:modelValue', false)"
    />
  </base-popup>
</template>

<script lang="ts" src="./MalePopup.ts" />
<style lang="scss" src="./malePopup.scss" />
