import { computed, defineComponent, ref } from 'vue';
import { TxtType, TxtWeight } from 'components/Txt';
import { useI18n } from 'vue-i18n';
import { Sex } from 'api/users/models/user';
import { useCurrency } from 'composables/currency';
import { BtnType } from 'components/Btn';
import BasePopup from 'components/PaidMessagesPopup/components/BasePopup';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { SubscriptionAction, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { useApiErrors } from 'composables/apiErrors';
import { toastEmitter, ToastType } from 'components/Toasts';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import PackageOfServices from 'components/PackageOfServices';

const MalePopup = defineComponent({
  components: {
    PackageOfServices,
    BasePopup,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const { formatCurrency } = useCurrency();
    const { showError } = useApiErrors();
    const model = ref('max');
    const store = useStore();
    const sex = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`].gender);

    const small = useServiceSettings(ServiceNames.MessageSubscriptionSmall);
    const medium = useServiceSettings(ServiceNames.MessageSubscriptionMedium);
    const max = useServiceSettings(ServiceNames.MessageSubscriptionMax);

    function getSubtitle(hours: number) {
      if (hours < 24 * 30) {
        return `${t(`components.paidMessages.${Sex.Male}.radioSubtitle`)} ${t(`components.paidMessages.${Sex.Male}.hours`, { hours }, hours)}`;
      }
      const month = Math.round(hours / 24 / 30);
      return `${t(`components.paidMessages.${Sex.Male}.radioSubtitle`)} ${t(`components.paidMessages.${Sex.Male}.month`, { month }, month)}`;
    }

    const options = computed(() => ({
      small: {
        title: `${t(`components.paidMessages.${Sex.Male}.radioTitle`,
          { days: small.value.days, price: formatCurrency(small.value.price) })}`,
        subtitle: getSubtitle(small.value.hours),
        profitably: false,
        price: small.value.price,
      },
      medium: {
        title: `${t(`components.paidMessages.${Sex.Male}.radioTitle`,
          { days: medium.value.days, price: formatCurrency(medium.value.price) })}`,
        subtitle: getSubtitle(medium.value.hours),
        profitably: false,
        price: medium.value.price,
      },
      max: {
        title: `${t(`components.paidMessages.${Sex.Male}.radioTitle`,
          { days: max.value.days, price: formatCurrency(max.value.price) })}`,
        subtitle: getSubtitle(max.value.hours),
        profitably: true,
        price: max.value.price,
      },
    }));
    const price = computed(() => options.value[model.value]?.price || 0);

    async function submit() {
      try {
        await store.dispatch(
          `${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.PurchaseMessageSubscription}`, model.value,
          );
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t(`components.paidMessages.${Sex.Male}.success`),
        });
        emit('update:modelValue', false);
      } catch (e) {
        showError(e);
      }
    }

    return {
      model,
      options,
      price,
      sex,
      submit,

      TxtType,
      TxtWeight,
      BtnType,
      Sex,
    };
  },
});

export default MalePopup;
