<template>
  <div class="stories-items">
    <profile-story
      v-if="!(profile && profile.highlight)"
      v-model:isCreateStoryOpen="isCreateStoryOpen"
    />

    <story-item
      v-for="(story, i) in stories"
      :key="story.id"
      :story="story"
      :class="{
        'profile-story': story.user_id === profile.id,
      }"

      @click="handleStory(i)"
    />

    <stories-view
      v-if="storyOpen"
      :selected="activeIndex"
      @close="storyOpen = false"
    />
  </div>
</template>

<script lang="ts" src="./Stories.ts"></script>
<style lang="scss" src="./stories.scss"></style>
