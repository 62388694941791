import { IProfile } from 'api/users/models/user';
import { StoriesGetter, useStoriesGetter } from 'store/stories';
import { UsersGetter, useUsersGetter } from 'store/users';
import { computed, defineComponent, ref, toRefs } from 'vue';
import { IStory } from 'api/stories/models/story';
import Story from './components/Story';

const Stories = defineComponent({
  components: {
    Story,
  },

  props: {
    selected: {
      type: Number,
      default: 0,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { selected } = toRefs(props);
    const index = ref(selected.value);

    const profile = computed(() => useUsersGetter<IProfile>(UsersGetter.Profile));
    const stories = computed(() => useStoriesGetter<IStory[]>(StoriesGetter.List));

    const isProfileStory = (story: IStory) => story.user.id === profile.value.id;

    const hasPrev = computed(() => index.value > 0);
    const hasNext = computed(() => index.value < stories.value.length - 1);

    const handleNext = () => {
      index.value += 1;

      if (index.value > stories.value.length - 1) {
        index.value = 0;

        emit('close');
      }
    };

    const handlePrev = () => {
      index.value -= 1;

      if (index.value < 0) {
        index.value = 0;

        emit('close');
      }
    };

    return {
      profile,
      index,
      stories,

      isProfileStory,
      hasPrev,
      hasNext,

      handleNext,
      handlePrev,
    };
  },
});

export default Stories;
