import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { CHATS_STORE_KEY } from '../constants';
import { ChatsAction } from '../enums';

export const useChatsAction = <T = unknown>(key: ChatsAction, payload?: T): void => {
  const store = useStore<typeof StoreInjectionKey>();
  store.dispatch(`${CHATS_STORE_KEY}/${key}`, payload);
};

export const useGenerateChatsAction = <T = unknown>(key: ChatsAction) => {
  const store = useStore<typeof StoreInjectionKey>();
  return (payload?: T) => store.dispatch(`${CHATS_STORE_KEY}/${key}`, payload);
};
