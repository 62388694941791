<template>
  <male-popup v-model="paidMessagesPopup" />
  <section
    ref="storySectionRef"
    v-touch:press="handleHoldStart"
    v-touch:tap="handleTap"
    v-touch:release="handleHoldEnd"
    :class="{
      story: true,
      [`story_${story.appearance.theme}`]: true,
    }"
    @touchstart="handleTouchPrevent"
    @touchend="handleTouchPrevent"
    @touchmove="handleTouchPrevent"
    @touchcancel="handleTouchPrevent"
  >
    <div class="story__controls">
      <context-menu
        v-if="!forProfile"
        :pos="1"
        :list="[
          {
            iconType: inFavorite ? IconType.Fill : IconType.Contour,
            iconName: IconName.Heart,
            text: inFavorite ? 'Убрать из избранного' : 'Добавить в избранное',
            action: handleFavorite
          },
          {
            iconType: IconType.Contour,
            iconName: IconName.Flag,
            text: 'Пожаловаться',
            action: handleClaimOpen,
          },
        ]"
        @changeVisible="handleActionsVisibleChange"
      >
        <icon-btn
          class="story__more"
          :color="MainColor.White"
        >
          <icon
            :type="IconType.Fill"
            :name="IconName.Dots"
            :size="Size.S"
          />
        </icon-btn>
      </context-menu>

      <icon-btn
        class="story__close"
        :color="MainColor.White"
        @click="$emit('close')"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xs"
        />
      </icon-btn>
    </div>

    <div class="story__progress">
      <div
        class="story__bar"
        :style="{ width: `${progress}%` }"
      />
    </div>

    <div
      :class="[
        'story__content',
        story.appearance.text_color ? `story__text-color_${story.appearance.text_color}` : undefined
      ]"
    >
      <div
        @click="handleUserInfoClick"
        @touchstart="handleUserInfoClick"
      >
        <div class="story__avatar">
          <img
            v-if="photo"
            :src="photo"
            :alt="forProfile ? profile.name : user.name"
          >
          <div
            v-else
            class="avatar__without_photo__container"
          >
            <icon
              class="avatar__without_photo"
              :type="IconType.Big"
              :name="iconName"
            />
          </div>
        </div>

        <txt
          :type="TxtType.Heading3"
          :weight="TxtWeight.Bold"
          class="story__title"
        >
          {{
            forProfile
              ? profile.name : user.name }}, {{ forProfile
            ? `${profile.age} ${$tc('user.age', profile.age)}`
            : `${user.age} ${$tc('user.age', user.age)}`
          }}
        </txt>
      </div>

      <txt
        :type="TxtType.Subheading"
        :weight="TxtWeight.Medium"
        :class="{
          story__message: true,
          [`story__message_${story.appearance.text_align}`]: true,
        }"
      >
        {{ story.content }}
      </txt>
    </div>

    <icon-btn
      v-if="hasPrev"
      class="story__arrow-left"
      @click="handlePrev"
    >
      <icon
        :name="IconName.StoryArrowLeft"
        :type="IconType.Contour"
        :color="MainColor.White"
        :size="Size.L"
      />
    </icon-btn>
    <icon-btn
      v-if="hasNext"
      class="story__arrow-right"
      @click="handleNext"
    >
      <icon
        :name="IconName.StoryArrowRight"
        :type="IconType.Contour"
        :color="MainColor.White"
        :size="Size.L"
      />
    </icon-btn>

    <div
      v-if="!forProfile"
      style="flex-grow: 1"
    />

    <div
      v-if="!forProfile"
      class="story__footer"
    >
      <input
        ref="inputRef"
        v-model="input"
        placeholder="Написать ответ"
        class="story__footer-input"
        @focus="inputFocus"
        @blur="inputBlur"
        @keyup.enter="handleSend"
      >

      <icon-btn
        class="story__footer-send"
        :size="Size.L"
        :color="isInputDirty ? MainColor.Violet : MainColor.Grey3"
        @click="handleSend"
      >
        <icon
          :type="IconType.Fill"
          :name="IconName.ArrowRight"
        />
      </icon-btn>
    </div>
    <div
      v-else
      class="story__footer story__footer_submit"
    >
      <prompt
        v-model="deletePrompt"
        :title="$t('pages.story.deletePrompt.title')"
        ok-text-path="pages.story.deletePrompt.ok"
        @ok="deleteStory"
      >
        <template #activator>
          <btn
            :type="BtnType.Secondary"
            w="100%"
          >
            {{ $t('pages.story.remove') }}
          </btn>
        </template>
        {{ $t('pages.story.deletePrompt.content') }}
      </prompt>
    </div>

    <modal
      :show="isShowClaim"
      :header="{
        title: 'Выберите причину жалобы',
        dir: ModalHeaderDirection.Rtl,
      }"

      @close="handleClaimClose"
    >
      <txt
        v-for="claim in claims"
        :key="claim"
        tag="div"
        :weight="TxtWeight.Medium"
        class="story-claim-item"
        @click="handleClaim(claim)"
      >
        {{ $t(`claims.${claim}`) }}
      </txt>
    </modal>
  </section>
</template>

<script lang="ts" src="./Story.ts"></script>
<style lang="scss" src="./story.scss"></style>
