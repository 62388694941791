export enum StoryTheme {
  Orange = 'orange',
  Blue = 'blue',
  Purple = 'purple',
  Cyan = 'cyan',
  Green = 'green',
  Yellow = 'yellow',
  Grey = 'grey',
}

export enum StoryTextColor {
  White = 'white',
  Black = 'black',
  Endeavour = 'endeavour',
  Green = 'green',
  Supernova = 'supernova',
  Japonica = 'japonica',
  HotPink = 'hot-pink',
  Red = 'red',
  Violet = 'violet',
}
