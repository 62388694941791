import { TxtWeight } from 'components/Txt';
import { Size } from 'core/styles';
import { computed, defineComponent } from 'vue';
import { User, ThemeColor } from 'api/users/models/user';
import { PropType } from '@vue/runtime-core';
import { IStory } from 'api/stories/models/story';

const StoryItem = defineComponent({
  props: {
    story: {
      type: Object as PropType<IStory>,
      required: true,
    },
  },

  setup(props) {
    const user = computed(() => new User(props.story.user));

    const activeThemeColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--theme-color-name') as ThemeColor
        || ThemeColor.Lavender;

    return {
      user,
      activeThemeColor,

      Size,
      TxtWeight,
    };
  },
});

export default StoryItem;
