<template>
  <popup
    :show="show"
  >
    <txt
      :type="TxtType.Heading2"
      class="mb-2"
    >
      {{ $t(`components.paidMessages.title`) }}
    </txt>

    <slot />
  </popup>
</template>

<script lang="ts" src="./BasePopup.ts" />
