import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import { TxtType } from 'components/Txt';
import AboutPaidServices from 'components/AboutPaidServices';
import { StorageKeys } from 'storage';

const AboutStory = defineComponent({
  components: {
    AboutPaidServices,
  },
  setup() {
    const store = useStore();
    const gender = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.gender);

    const serviceSettings = useServiceSettings(ServiceNames.Highlight);

    return {
      gender,
      serviceSettings,

      StorageKeys,
      TxtType,
    };
  },
});

export default AboutStory;
