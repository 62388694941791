<template>
  <teleport to="#stories">
    <div class="stories">
      <div
        class="stories__rail"
        :style="{
          width: `calc(100% * ${stories.length})`,
          left: `calc(-100% * ${index})`,
        }"
      >
        <story
          v-for="(story, i) in stories"
          :key="story.id"
          :story="story"
          :active="i === index"
          :for-profile="isProfileStory(story)"
          :has-prev="hasPrev"
          :has-next="hasNext"

          @next="handleNext"
          @prev="handlePrev"
          @close="$emit('close')"
        />
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" src="./Stories.ts"></script>
<style lang="scss" src="./stories.scss"></style>
