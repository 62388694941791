<template>
  <about-paid-services
    :local-storage-key="StorageKeys.AboutStoryViewed"
    :button-title="$t('components.aboutStory.submit')"
  >
    <template #default="{ themeColor }">
      <txt :type="TxtType.Heading2">{{ $t('components.aboutStory.title') }}</txt>
      <p class="my-4">{{ $t('components.aboutStory.description') }}</p>
      <txt
        :type="TxtType.Heading4"
        class="mb-4"
      >
        {{ $t('components.aboutStory.listTitle') }}
      </txt>
      <ul>
        <li class="story-about__li">
          <span class="story-about__icon-wrapper">
            <img
              v-if="themeColor === 'lavender'"
              src="./assets/lavender-1.svg"
            >
            <img
              v-else-if="themeColor === 'red'"
              src="./assets/red-1.svg"
            >
            <img
              v-else
              src="./assets/blue-1.svg"
            >
          </span>
          {{ $t('components.aboutStory.li1') }}
        </li>
        <li class="story-about__li">
          <span class="story-about__icon-wrapper">
            <img
              src="./assets/theme.svg"
            >
          </span>
          {{ $t('components.aboutStory.li2') }}
        </li>
        <li class="story-about__li">
          <span class="story-about__icon-wrapper">
            <img
              v-if="themeColor === 'lavender'"
              src="./assets/lavender-3.svg"
            >
            <img
              v-else-if="themeColor === 'red'"
              src="./assets/red-3.svg"
            >
            <img
              v-else
              src="./assets/blue-3.svg"
            >
          </span>
          {{ $t('components.aboutStory.li3') }}
        </li>
        <li class="story-about__li">
          <span class="story-about__icon-wrapper">
            <img
              v-if="themeColor === 'lavender'"
              src="./assets/lavender-4.svg"
            >
            <img
              v-else-if="themeColor === 'red'"
              src="./assets/red-4.svg"
            >
            <img
              v-else
              src="./assets/blue-4.svg"
            >
          </span>
          {{ $t(`components.aboutStory.li4.${gender}`) }}
        </li>
      </ul>
      <p>{{ $tc('components.aboutStory.price', serviceSettings.price) }}</p>
      <p>{{ $tc('components.aboutStory.duration', serviceSettings.hours) }}</p>
    </template>
  </about-paid-services>
</template>

<script lang="ts" src="./AboutStory.ts" />
<style lang="scss" src="./aboutStory.scss" />
