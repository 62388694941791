<template>
  <div class="package-of-service">
    <txt
      v-if="showTitle"
      :type="TxtType.Heading2"
      class="mt-2"
    >
      {{ $t('pages.profile.wallet.packageOfServices.title') }}
    </txt>
    <radio-group
      v-model="selectedPackage"
      :values="paidPackages"
      :order="-1"
      expandable
      class="mt-5"
    >
      <template #default="{ value, checked }">
        <div
          class="package-of-service__bg-image"
          :class="{ 'package-of-service__bg-image--don-gradient': gender === Sex.Male && value.id === 7 }"
        >
          <img
            v-if="isGoodPrice(value) && gender === Sex.Female"
            class="package-of-service__img"
            src="./assets/female.svg"
            alt="bg"
          >
          <img
            v-else-if="gender === Sex.Male && value.id === 3"
            class="package-of-service__img"
            src="./assets/denzl-bg.svg"
            alt="bg"
          >
          <img
            v-else-if="gender === Sex.Male && value.id === 7"
            class="package-of-service__img"
            src="./assets/don-bg.svg"
            alt="bg"
          >
        </div>
        <txt :weight="TxtWeight.Bold">
          {{ getTitle(value) }}
          <component :is="value.final_price_with_discount ? 's' : 'span'">
            {{ formatCurrency(value.cost) }}
          </component>
          <span
            v-if="value.final_price_with_discount"
            class="package-of-service__discount-price"
          >
            {{ formatCurrency(value.final_price_with_discount) }}
          </span>
        </txt>
        <txt
          v-if="isGoodPrice(value)"
          :weight="TxtWeight.Bold"
        >
          <br>
          {{ $t('pages.profile.wallet.packageOfServices.goodPrice') }}
        </txt>
        <span v-if="isForever(value)">
          <br>
          <b>{{ $t('pages.profile.wallet.packageOfServices.forever') }}</b>
        </span>
        <span v-if="value.id === 3">
          <br>
          <b>Без ограничений на 3 месяца:</b>
        </span>
        <transition
          name="package-of-service-param"
          :duration="2000"
          @enter="setMaxHeight"
          @before-leave="setMaxHeight"
        >
          <span
            v-if="checked"
            class="package-of-service-param"
          >
            <template
              v-for="param in value.params"
            >
              <span
                v-for="textResult in getParamsText(param, value)"
                class="package-of-service-param__item"
              >
                {{ textResult }}
              </span>
            </template>
          </span>
        </transition>
      </template>
    </radio-group>

    <div
      class="package-of-service__buy-wrapper"
      :class="{ 'package-of-service__buy-wrapper--fixed': !staticBtn }"
    >
      <btn
        w="100%"
        @click="showBuyPrompt"
      >
        {{ $t('pages.profile.wallet.packageOfServices.buy', { price: selectedPrice }) }}
      </btn>
    </div>

    <div
      v-if="cancelButton"
      class="package-of-service__cancel-wrapper"
    >
      <btn
        w="100%"
        :type="BtnType.Secondary"
        @click="cancel"
      >
        {{ $t('pages.profile.wallet.packageOfServices.cancel') }}
      </btn>
    </div>
  </div>

  <prompt
    v-model="isShowPrompt"
    :width="293"
    :title="promptText"
    ok-text-path="pages.profile.wallet.packageOfServices.prompt.submit"
    @ok="buyPaidPackage"
  />
</template>

<script lang="ts" src="./PackageOfServices.ts" />
<style lang="scss" src="./packageOfServices.scss" />
