import { defineComponent, h } from 'vue';
import { useI18n } from 'vue-i18n';
import { Size } from 'core/styles';
import Txt, { TxtWeight } from '../Txt';
import Icon, { IconName, IconType } from '../Icon';
import Btn from '../Btn';

import './empty.scss';

export default defineComponent({
  props: {
    actionTextPath: {
      type: String,
      default: 'empty.goToCatalog',
    },
    textPath: {
      type: String,
      default: 'empty.noResult',
    },
  },

  emits: ['actionClick'],

  setup(props, { slots, emit }) {
    const { t } = useI18n();

    return () => h('div', {
      class: 'empty',
    }, [
      h('div', {
        class: 'empty-icon',
      }, h(Icon, {
        name: IconName.Research,
        type: IconType.Big,
      })),
      h(Txt, {
        weight: TxtWeight.Medium,
        leading: Size.L,
      }, {
        default: () => t(props.textPath),
      }),
      slots.action ? slots.action() : h(Btn, {
        w: '100%',
        class: 'empty-action',
        onClick: () => emit('actionClick'),
      }, {
        default: () => t(props.actionTextPath),
      }),
    ]);
  },
});
