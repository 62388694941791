import { IProfile, IUser, Sex } from 'api/users/models/user';
import { ClaimType } from 'api/users/models/claims';
import { IconName, IconType } from 'components/Icon';
import { ModalHeaderDirection } from 'components/Modal';
import { TxtType, TxtWeight } from 'components/Txt';
import { MainColor, Size } from 'core/styles';
import { Nullable } from 'core/types';
import { clamp, ifElse } from 'ramda';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { computed, defineComponent, onUnmounted, ref, toRefs, watchEffect, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useGenerateChatsAction } from 'store/chats/hooks';
import { ChatsAction } from 'store/chats/enums';
import { BtnType } from 'components/Btn';
import { STORIES_STORE_KEY, StoriesAction } from 'store/stories';
import Prompt from 'components/Prompt';
import { useRouter } from 'vue-router';
import MalePopup from 'components/PaidMessagesPopup/MalePopup';
import { CHATS_STORE_KEY } from 'store/chats';
import { useFavorites } from 'composables/favorites';
import { useKeyboardScroll } from 'composables/keyboardScroll';
import { usePackageRequiredErrorHandler } from 'composables/packageRequiredErrorHandler';

const Story = defineComponent({
  components: {
    Prompt,
    MalePopup,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    forProfile: {
      type: Boolean,
      default: false,
    },
    story: {
      type: Object,
      required: true,
    },
    hasPrev: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['prev', 'next', 'close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const { active } = toRefs(props);

    const paidMessagesPopup = ref(false);
    const store = useStore();

    const { showError } = usePackageRequiredErrorHandler(paidMessagesPopup, props.story.user?.id);

    const router = useRouter();

    const storySectionRef = ref();
    const input = ref('');
    const isInputFocused = ref(false);
    const inputRef = ref<HTMLInputElement>();
    const deletePrompt = ref(false);

    const isInputDirty = computed(() => !!input.value);

    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const user = computed<IUser>(() => (props.story.user || profile.value));

    const isShowClaim = ref(false);

    const { isFavoriteUser } = useFavorites();
    const inFavorite = computed(() => isFavoriteUser(user.value));
    const { toggleFavorite } = useFavorites();
    const handleFavorite = async () => {
      toggleFavorite(user);
    };

    const claims = Object.values(ClaimType);

    const progress = ref(0);
    const isHolded = ref(false);
    let intervalId: Nullable<NodeJS.Timeout> = null;

    watch(deletePrompt, (val) => {
      if (val) {
        isHolded.value = true;
      } else {
        isHolded.value = false;
      }
    });

    watchEffect(() => {
      if (active.value) {
        intervalId = setInterval(() => {
          if (!isHolded.value) {
            progress.value = clamp(0, 100, progress.value + 1);

            if (progress.value === 100 && intervalId) {
              emit('next');
              blur();
              progress.value = 0;

              clearInterval(intervalId);
              intervalId = null;
            }
          }
        }, 40);
      } else if (intervalId) {
        progress.value = 0;

        clearInterval(intervalId);
        intervalId = null;
      }
    });

    onUnmounted(() => {
      if (intervalId) {
        progress.value = 0;

        clearInterval(intervalId);
        intervalId = null;
      }
    });

    const handleHoldStart = (e: Event) => {
      const target = e.target as HTMLElement;

      if (!target.closest('.story__controls')) {
        isHolded.value = true;
      }
    };

    const handleHoldEnd = (e: Event) => {
      const target = e.target as HTMLElement;

      if (isInputFocused.value) return;

      if (target?.closest('.story__controls')) return;

      isHolded.value = false;
    };

    const handleActionsVisibleChange = (val: boolean) => {
      if (isShowClaim.value) return;

      isHolded.value = val;
    };

    function handlePrev() {
      blur();
      emit('prev');
      if (intervalId) {
        progress.value = 0;
        input.value = '';

        clearInterval(intervalId);
        intervalId = null;
      }
    }

    function handleNext() {
      blur();
      emit('next');
      if (intervalId) {
        progress.value = 0;
        input.value = '';

        clearInterval(intervalId);
        intervalId = null;
      }
    }

    const handleTap = (e: TouchEvent) => {
      const touch = e.changedTouches && e.changedTouches[0];
      const target: HTMLElement = e.target as never;

      if (touch
        && !target.closest('.story__controls')
        && !target.closest('.story__footer')
      ) {
        const areaSize = window.innerWidth * 0.25;

        if (touch.clientX < areaSize) {
          emit('prev');
          blur();

          if (intervalId) {
            progress.value = 0;
            input.value = '';

            clearInterval(intervalId);
            intervalId = null;
          }
        } else if (touch.clientX > areaSize * 3) {
          emit('next');
          blur();

          if (intervalId) {
            progress.value = 0;
            input.value = '';

            clearInterval(intervalId);
            intervalId = null;
          }
        }
      }
    };

    function handleTouchPrevent(e:TouchEvent) {
      const target: HTMLElement = e.target as never;

      if (!target.closest('.story__controls') && !target.closest('.story__footer')) {
        e.preventDefault();
      }
    }

    function inputFocus() {
      isInputFocused.value = true;
      isHolded.value = true;
      window?.scrollTo(0, 0);
    }

    function inputBlur() {
      isInputFocused.value = false;
      isHolded.value = false;
    }

    const handleClaimOpen = () => {
      isShowClaim.value = true;
      isHolded.value = true;
    };

    const handleClaimClose = () => {
      isShowClaim.value = false;
      isHolded.value = false;
    };

    function handleClaim(type: ClaimType) {
      const data = {
        userId: user.value.id,
        type,
      };
      store.dispatch(`${USER_STORE_KEY}/${UsersAction.PutClaim}`, data);

      handleClaimClose();

      toastEmitter.emit('toast', {
        type: ToastType.Success,
        message: t('pages.claimSuccess'),
        offset: 52,
      });
    }

    const handleUserInfoClick = async () => {
      await router.push(props.story?.user?.id ? `/user/${props.story.user.id}` : '/');
      emit('close');
    };

    watch(paidMessagesPopup, (v) => {
      isHolded.value = v;
    });

    const sendMessage = useGenerateChatsAction(ChatsAction.ScheduleNewMessage);
    const handleSend = async () => {
      if (!isInputDirty.value) {
        inputRef.value?.focus();
        return;
      }

      if (profile.value) {
        sendMessage({
          userId: user.value.id,
          data: {
            refer: 'stories',
            text: input.value,
            type: 'text',
          },
          callbacks: {
            onSuccess: () => {
              toastEmitter.emit('toast', {
                type: ToastType.Success,
                message: t('pages.catalog.storyMessageSuccess'),
                offset: 52,
              });
              store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`).catch((e) => showError(e));
            },
            onError: () => {
              toastEmitter.emit('toast', {
                type: ToastType.Error,
                message: t('pages.catalog.storyMessageError'),
                offset: 52,
              });
            },
          },
        });
      }

      input.value = '';
    };

    const photo = computed(() => user.value.photos?.[0]?.path);

    async function deleteStory() {
      try {
        await store.dispatch(`${STORIES_STORE_KEY}/${StoriesAction.DeleteStory}`);
        emit('close');
      } catch (e) {
        showError(e);
      }
    }

    const iconName = computed(ifElse(
      () => user.value.gender === Sex.Male,
      () => IconName.Male,
      () => IconName.Female,
    ));

    useKeyboardScroll(inputRef);

    function blur() {
      inputRef.value?.blur();
    }

    return {
      storySectionRef,
      deletePrompt,
      paidMessagesPopup,
      photo,
      iconName,

      isShowClaim,
      inFavorite,
      handleFavorite,
      claims,
      profile,
      user,
      progress,
      handleClaim,
      handleClaimOpen,
      handleTouchPrevent,
      handleHoldStart,
      handleHoldEnd,
      handleActionsVisibleChange,
      handleTap,
      handleNext,
      handlePrev,
      handleClaimClose,
      handleSend,
      handleUserInfoClick,
      input,
      inputRef,
      isInputFocused,
      isInputDirty,
      inputFocus,
      inputBlur,
      deleteStory,

      IconType,
      IconName,
      MainColor,
      Size,
      ModalHeaderDirection,
      TxtWeight,
      TxtType,
      BtnType,
    };
  },
});

export default Story;
