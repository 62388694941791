import { defineComponent } from 'vue';
import { BtnType } from 'components/Btn';
import { TxtType } from 'components/Txt';
import Prompt from 'components/Prompt';
import InsufficientPrompt from 'components/InsufficientPrompt';

const BasePopup = defineComponent({
  components: {
    Prompt,
    InsufficientPrompt,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'submit'],
  setup() {
    return {
      BtnType,
      TxtType,
    };
  },
});

export default BasePopup;
