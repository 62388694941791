<template>
  <about-story />
  <teleport to="#stories">
    <section
      :class="{
        'create-story': true,
        [`create-story_${theme}`]: true,
      }"
    >
      <div class="create-story__controls">
        <icon-btn
          class="create-story__control"
          :color="MainColor.White"
          @click="$emit('close')"
        >
          <icon
            :type="IconType.Contour"
            :name="IconName.Crosses"
            :size="Size.S"
          />
        </icon-btn>

        <txt
          v-if="isSelectTheme"
          :type="TxtType.SmallText"
          :weight="TxtWeight.Bold"
          class="create-story__control create-story__control_text"
          @click="handleReady"
        >
          {{ $t('pages.createStory.ready') }}
        </txt>
        <div v-else>
          <icon-btn
            class="create-story__control"
            :color="MainColor.White"
            @click="isSelectTheme = true"
          >
            <icon
              :type="IconType.Contour"
              :name="IconName.Painting"
            />
          </icon-btn>
        </div>

        <div class="create-story__control-align">
          <icon-btn
            class="create-story__control"
            :color="MainColor.White"
            @click="handleChangeAlign"
          >
            <icon
              :type="IconType.Contour"
              :name="aligns[align]"
              :size="Size.S"
            />
          </icon-btn>
        </div>
      </div>

      <div class="create-story__content">
        <div class="create-story__avatar">
          <img
            v-if="photo"
            :src="photo"
            :alt="profile.name"
          >
          <div
            v-else
            class="create-story__avatar-icon-wrapper"
          >
            <icon
              class="create-story__avatar-icon"
              :name="iconName"
              :type="IconType.Big"
              :color="MainColor.Grey5"
            />
          </div>
        </div>

        <txt
          :type="TxtType.Heading3"
          :weight="TxtWeight.Bold"
          :class="{
            'create-story__title': true,
            [`create-story__text-color_${textColor}`]: true,
          }"
        >
          {{ profile && profile.name }}, {{ `${profile && profile.age} ${$tc('user.age', profile && profile.age)}` }}
        </txt>

        <div class="create-story__textarea-wrapper">
          <textarea
            id="create-story-text"
            ref="textareaRef"
            v-model="text"
            :class="{
              'create-story__message': true,
              [`create-story__message_${align}`]: true,
              [`create-story__text-color_${textColor}`]: true,
            }"
            placeholder="Добавьте сообщение..."
            :style="{ height: `${height}px` }"
            autofocus="autofocus"
            maxlength="255"
            @input="handleInput"
          />
          <span class="create-story__text-length">{{ text.length }} / 255</span>
        </div>
      </div>

      <div class="create-story__footer">
        <div
          v-if="isSelectTheme"
          class="create-story__themes"
        >
          <div
            v-for="t in themes"
            :key="t"
            :class="{
              'create-story__theme': true,
              [`create-story__theme_${t}`]: true,
              'create-story__theme_active': t === theme
            }"
            @click="theme = t"
          />
        </div>

        <btn
          v-else
          :type="BtnType.Primary"
          w="100%"
          @click="goToNextStep"
        >
          {{ $t('pages.createStory.goToAudience') }}
        </btn>
      </div>
    </section>
  </teleport>

  <modal
    :show="isNextStep"
    :header="{
      title: $t('pages.createStory.whoWillSee'),
    }"

    @close="isNextStep = false"
  >
    <section class="create-story-form">
      <div class="create-story-form__row">
        <txt
          class="create-story-form__title"
          :type="TxtType.Heading4"
        >
          {{ $t('pages.createStory.city') }}
        </txt>

        <div class="create-story-form__text">
          <autocomplete
            ref="cityRef"
            v-model="city"
            :label="$t('pages.signup.city')"
            :items="cities"
            two-line
            no-filter
            item-value="id"
            item-text="name"
            select
          >
            <template #item="{ item }">
              <div>{{ item.name }}</div>
              <div>{{ item.region }}</div>
            </template>
          </autocomplete>
        </div>
      </div>

      <div class="create-story-form__row">
        <txt
          class="create-story-form__title"
          :type="TxtType.Heading4"
        >
          {{ $t('pages.form.sex') }}
        </txt>

        <div class="create-story-form__sex create-story-form__fields">
          <btn
            :class="{
              'create-story-form__fields-field': true,
              'create-story-form__sex__button': true,
              'create-story-form__sex__button_not-active': sex !== Sex.Female,
            }"

            @click="sex = Sex.Female"
          >
            {{ $t(`user.sex.${Sex.Female}`) }}
          </btn>

          <btn
            :class="{
              'create-story-form__fields-field': true,
              'create-story-form__sex__button': true,
              'create-story-form__sex__button_not-active': sex !== Sex.Male,
            }"

            @click="sex = Sex.Male"
          >
            {{ $t(`user.sex.${Sex.Male}`) }}
          </btn>
        </div>
      </div>

      <template v-if="showExtraFields">
        <div class="create-story-form__row">
          <txt
            class="create-story-form__title"
            :type="TxtType.Heading4"
          >
            {{ $t('pages.form.age') }}
          </txt>

          <div class="create-story-form__fields">
            <textfield
              v-model.number="age[0]"
              class="create-story-form__fields-field create-story-form__fields-field-text"
              :lt="$t('pages.form.from')"
            />

            <textfield
              v-model.number="age[1]"
              class="create-story-form__fields-field create-story-form__fields-field-text"
              :lt="$t('pages.form.to')"
            />
          </div>
        </div>

        <div class="create-story-form__row">
          <div class="create-story-form__toogler-label">
            <txt
              class="create-story-form__title"
              :type="TxtType.Heading4"
            >
              {{ $t('pages.createStory.hideFromContacts') }}
            </txt>
          </div>

          <toggler
            v-model="hideFrom"
            class="create-story-form__toogler-form"
          />
        </div>
      </template>
    </section>

    <div class="create-story-submit">
      <btn
        w="100%"

        @click="submitPrompt = true"
      >
        {{ $t('pages.createStory.submit', { n: serviceInfo.hours }) }}
      </btn>
    </div>
  </modal>
  <prompt
    v-model="submitPrompt"
    :title="serviceInfo.boughtText || $t('pages.createStory.submitPrompt.title', { price: serviceInfo.price })"
    ok-text-path="pages.createStory.submitPrompt.confirm"
    @ok="checkBalance"
  />
  <insufficient-prompt v-model="insufficientPrompt" />
</template>

<script lang="ts" src="./CreateStory.ts"></script>
<style lang="scss" src="./create-story.scss"></style>
