import { useStore } from 'vuex';
import { useFetching } from 'composables/fetching';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { Ref } from 'vue';
import { useApiErrors } from 'composables/apiErrors';
import { IProfile, Sex } from 'api/users/models/user';
import { Nullable } from 'core/types';
import { CHATS_STORE_KEY, ChatsGetter } from 'store/chats';

export function usePackageRequiredErrorHandler(flag: Ref<boolean>, userId?: number) {
  const store = useStore();

  const { showError: _showError } = useApiErrors();

  const canSendMessages = () => {
    const profile: Nullable<IProfile> = store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`];
    if (profile?.gender === Sex.Male) {
      const chat = (userId || userId === 0) && store.getters[`${CHATS_STORE_KEY}/${ChatsGetter.ChatByUserId}`](userId);

      return !!(profile.remaining_new_dialogues || (chat && chat.last_message));
    }
    return true;
  };

  if (!canSendMessages()) {
    store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchPaidPackages}`).catch(_showError);
  }

  const { fetch: fetchPaidMessages } = useFetching();
  const showError = (e: any) => {
    if (e?.status !== 'package_required') {
      _showError(e);
      return;
    }

    if (store.getters[`${USER_STORE_KEY}/${UsersGetter.PaidPackages}`].length) {
      // eslint-disable-next-line no-param-reassign
      flag.value = true;
    } else {
      fetchPaidMessages(store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchPaidPackages}`)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          flag.value = true;
        })
        .catch((e) => _showError(e))).catch(_showError);
    }
  };

  return {
    showError,
  };
}
