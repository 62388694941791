import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import {
  IProfilePaidPackage,
  PaidPackage,
  PaidPackageParams,
} from 'api/users/models/paidPackages/interfaces';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { useCurrency } from 'composables/currency';
import { useI18n } from 'vue-i18n';
import { PaidPackageDuration, PaidPackageParamsType } from 'api/users/models/paidPackages/enums';
import { TxtType, TxtWeight } from 'components/Txt';
import { Nullable } from 'core/types';
import Prompt from 'components/Prompt';
import { useApiErrors } from 'composables/apiErrors';
import { toastEmitter, ToastType } from 'components/Toasts';
import { Sex } from 'api/users/models/user';
import { BtnType } from 'components/Btn';

interface IExtendedPaidPackage extends PaidPackage {
  disabled: boolean;
}

const PackageOfServices = defineComponent({
  components: {
    Prompt,
  },
  props: {
    staticBtn: {
      type: Boolean,
      default: false,
    },
    cancelButton: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['cancel'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { showError } = useApiErrors();
    const boughtPackages =
      computed<IProfilePaidPackage[]>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfilePaidPackages}`]);
    const paidPackages = computed<IExtendedPaidPackage[]>(
      () => store.getters[`${USER_STORE_KEY}/${UsersGetter.AvailablePaidPackages}`].map((i: PaidPackage) => ({
        ...i,
        disabled: boughtPackages.value.some((p) => i.unique && p.id === i.id),
      })),
    );
    const gender = computed<Nullable<Sex>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.gender);

    const { formatCurrency } = useCurrency();

    function getTitle(paidPackage: IExtendedPaidPackage) {
      return t('pages.profile.wallet.packageOfServices.package', {
        package: paidPackage.title,
        duration: t(`pages.profile.wallet.packageOfServices.duration.${paidPackage.duration_type}`,
          { n: paidPackage.duration },
          paidPackage.duration),
      });
    }

    function getParamsText(paidPackageParams: PaidPackageParams, p: IExtendedPaidPackage) {
      const result: any[] = [];
      const showOnlyText = p.id === 3; // @todo дензл возвращаем только текст, вынести в константы айди пакетов, по ним есть уникальность вывода

      // paidPackageParams.title может содержать на один платный сервис несколько описаний разделенных через |
      paidPackageParams.title.split('|').forEach((v) => {
        if (showOnlyText || paidPackageParams.type === PaidPackageParamsType.DurationForever) {
          result.push(v);
          return;
        }
        if (paidPackageParams.type === PaidPackageParamsType.Count) {
          result.push(`${v} ${t('pages.profile.wallet.packageOfServices.count',
            { n: paidPackageParams.value }, paidPackageParams.value)}`);
          return;
        }

        result.push(`${v} ${t(`pages.profile.wallet.packageOfServices.durationType.${paidPackageParams.type}`,
          { n: paidPackageParams.value },
          paidPackageParams.value)}`);
      });

      return result;
    }

    const getLastPackage = () => {
      for (let i = paidPackages.value.length - 1; i > 0; i--) {
        if (boughtPackages.value.some((p) => paidPackages.value[i].unique && p.id === paidPackages.value[i].id)) {
          // eslint-disable-next-line no-continue
          continue;
        }
        return paidPackages.value[i];
      }
      return paidPackages.value[0];
    };
    const selectedPackageId = ref<Nullable<number>>(getLastPackage()?.id);
    const selectedPackage = computed<Nullable<IExtendedPaidPackage>>({
      get: () => paidPackages.value.find((p) => p.id === selectedPackageId.value) || null,
      set: (val) => {
        selectedPackageId.value = val?.id || null;
      },
    });

    const selectedPrice = computed(() => selectedPackage.value &&
      formatCurrency(selectedPackage.value.final_price_with_discount || selectedPackage.value.cost));

    async function buyPaidPackage() {
      if (!selectedPackage.value) return;
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.BuyPaidPackages}`, selectedPackage.value.id);
        selectedPackageId.value = getLastPackage().id;
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.profile.wallet.packageOfServices.success'),
        });
        if (props.cancelButton) cancel();
      } catch (e) {
        showError(e);
      }
    }

    const isShowPrompt = ref(false);
    function showBuyPrompt() {
      if (!selectedPackage.value) return;
      isShowPrompt.value = true;
    }
    function cancel() {
      emit('cancel');
    }
    const promptText = computed(() => {
      if (!selectedPackage.value) return '';
      return t('pages.profile.wallet.packageOfServices.prompt.title', {
        package: selectedPackage.value.title,
        price: selectedPrice.value,
      });
    });

    function isForever(p: IExtendedPaidPackage) {
      return p?.duration_type === PaidPackageDuration.DurationForever;
    }

    function isGoodPrice(p: IExtendedPaidPackage) {
      return p.id === paidPackages.value[paidPackages.value.length - 1]?.id;
    }

    function setMaxHeight(el: HTMLSpanElement) {
      el?.style.setProperty('max-height', `${el.scrollHeight}px`);
    }

    return {
      paidPackages,
      formatCurrency,
      selectedPackage,
      selectedPrice,
      isShowPrompt,
      showBuyPrompt,
      buyPaidPackage,
      promptText,
      isForever,
      isGoodPrice,
      gender,
      setMaxHeight,
      cancel,

      getTitle,
      getParamsText,

      TxtWeight,
      Sex,
      TxtType,
      BtnType,
    };
  },
});

export default PackageOfServices;
