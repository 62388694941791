<template>
  <div
    v-if="profile"
    class="catalog-story profile-story"
  >
    <avatar
      :has-plus="!profileStory"
      :story="profileStory"
      :user="profile"
      :size="Size.L"
      :iconColor="activeThemeColor"
      class="catalog-story__avatar"
      @click="handleStory"
    />

    <txt
      class="profile-story__title"
      :leading="Size.S"
      :weight="TxtWeight.Medium"
    >
      {{ $t('pages.catalog.profileStory') }}
    </txt>

    <create-story
      v-if="!profileStory && isCreateStory"
      @close="isCreateStory = false"
    />

    <teleport to="#stories">
      <div
        v-if="profileStory && isStoryOpen"
        class="profile-story-stories"
      >
        <story-view
          for-profile
          active
          :story="profileStory"

          @next="isStoryOpen = false"
          @prev="isStoryOpen = false"
          @close="isStoryOpen = false"
        />
      </div>
    </teleport>
  </div>
</template>

<script lang="ts" src="./ProfileStory.ts"></script>
<style lang="scss" src="./profile-story.scss"></style>
