import { IUser } from 'api/users/models/user';
import { StoreInjectionKey } from 'store/constants';
import { StoriesAction, StoriesGetter, useStoriesAction, useStoriesGetter } from 'store/stories';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import StoriesView from 'views/Stories';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import StoryItem from '../StoryItem';
import ProfileStory from '../ProfileStory';

const Stories = defineComponent({
  components: {
    StoryItem,
    StoriesView,
    ProfileStory,
  },
  props: {
    isStoriesOpen: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:isStoriesOpen'],

  setup(props, { emit }) {
    const store = useStore<typeof StoreInjectionKey>();
    const { path, query } = useRoute();
    const { push, replace } = useRouter();

    useStoriesAction(StoriesAction.GetStories);

    const storyOpen = ref(false);
    const isCreateStoryOpen = ref(false);
    watch(storyOpen, (val) => emit('update:isStoriesOpen', val || isCreateStoryOpen.value));
    watch(isCreateStoryOpen, (val) => emit('update:isStoriesOpen', val || isCreateStoryOpen.value));
    onBeforeUnmount(() => emit('update:isStoriesOpen', false));
    const activeIndex = ref(0);

    const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as IUser);
    const stories = computed<any[]>(() => useStoriesGetter(StoriesGetter.List) ?? []);

    onMounted(() => {
      if (!query?.story) return;
      const id = Number(query?.story);
      if (!stories.value[id]) {
        push(path);
        return;
      }
      activeIndex.value = id || 0;
      storyOpen.value = true;
    });

    watch(storyOpen, (value) => {
      if (!value && query && query.story) push(path);
    });

    const handleStory = (i: number) => {
      replace(`${path}?story=${i}`);
      storyOpen.value = true;
      activeIndex.value = i;
    };

    return {
      isCreateStoryOpen,
      profile,
      stories,
      storyOpen,
      activeIndex,
      handleStory,
    };
  },
});

export default Stories;
