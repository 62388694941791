<template>
  <div class="catalog-story">
    <avatar
      :user="user"
      :story="story"
      :size="Size.L"
      :iconColor="activeThemeColor"
      class="catalog-story__avatar"
    />

    <txt
      class="catalog-story__name"
      :leading="Size.S"
      :weight="TxtWeight.Medium"
    >
      {{ user.name }}
    </txt>
  </div>
</template>

<script lang="ts" src="./StoryItem.ts"></script>
<style lang="scss" src="./story-item.scss"></style>
