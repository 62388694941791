import { ThemeColor, IProfile } from 'api/users/models/user';
import { MainColor, Size } from 'core/styles';
import { UsersGetter, useUsersGetter } from 'store/users';
import { TxtWeight } from 'components/Txt';
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import StoryView from '../../../Stories/components/Story';
import CreateStory from '../../../Rooms/components/CreateStory';

const Stories = defineComponent({
  components: {
    StoryView,
    CreateStory,
  },
  props: {
    isCreateStoryOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open-story', 'update:isCreateStoryOpen'],

  setup(_, { emit }) {
    const isCreateStory = ref(false);
    const isStoryOpen = ref(false);

    const profile = computed(() => useUsersGetter<IProfile>(UsersGetter.Profile));
    const profileStory = computed(() => profile.value.highlight);

    function handleStory() {
      if (!profileStory.value) {
        isCreateStory.value = true;
      } else {
        // isStoryOpen.value = true;
        emit('open-story');
      }
    }

    watch(isCreateStory, (val) => {
      emit('update:isCreateStoryOpen', val);
    });

    onBeforeUnmount(() => {
      emit('update:isCreateStoryOpen', false);
    });

    const activeThemeColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--theme-color-name') as ThemeColor
        || ThemeColor.Lavender;

    return {
      isCreateStory,
      isStoryOpen,
      profileStory,
      profile,
      handleStory,
      activeThemeColor,

      MainColor,
      Size,
      TxtWeight,
    };
  },
});

export default Stories;
