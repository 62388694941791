import type { Nullable } from 'core/types';
import { IStoryUser, StoryUser } from 'api/stories/models/StoryUser';
import { StoryTheme, StoryTextColor } from './enums/StoryTheme';
import type { IStory } from './interfaces/IStory';

export class Story implements IStory {
  public static create(raw: IStory): Story {
    return new Story(raw);
  }

  appearence: {
    theme: StoryTheme;
    text_align: string;
    text_color: Nullable<StoryTextColor>;
  }
  content: string;

  deleted_at: Nullable<string>;
  expired_at: string;
  id: number;
  unread: boolean;
  user: IStoryUser;

  public constructor(raw: IStory) {
    this.id = raw.id;
    this.appearence = raw.appearence;

    this.content = raw.content;
    this.expired_at = raw.expired_at;
    this.unread = raw.unread;

    this.user = new StoryUser(raw.user);
  }
}
