import { StoryTheme, StoryTextColor } from 'api/stories/models/story';
import { IProfile, Sex } from 'api/users/models/user';
import { BtnType } from 'components/Btn';
import { IconName, IconType } from 'components/Icon';
import { TxtType, TxtWeight } from 'components/Txt';
import Autocomplete from 'components/Autocomplete';
import { MainColor, Size, TextAlign } from 'core/styles';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { computed, defineComponent, ref } from 'vue';
import { ICity } from 'core/types';
import { StoriesAction, useGenerateStoriesAction } from 'store/stories';
import { useApiErrors } from 'composables/apiErrors';
import Prompt from 'components/Prompt';
import { ifElse } from 'ramda';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import InsufficientPrompt from 'components/InsufficientPrompt';
import { useStore } from 'vuex';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import AboutStory from 'views/Stories/components/AboutStory';
import { usersFilterValueDefaults } from 'core/values';

const CreateStory = defineComponent({
  components: {
    Autocomplete,
    Prompt,
    AboutStory,
    InsufficientPrompt,
  },

  emits: ['close'],

  setup(_, { emit }) {
    const store = useStore();

    const cities = computed(() => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`]);

    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const textColor = ref(StoryTextColor.White);
    const theme = ref(StoryTheme.Orange);
    const photo = ref(profile.value.photos[0]?.path || '');
    const align = ref(TextAlign.Center);
    const text = ref('');
    const textareaRef = ref<HTMLInputElement>();
    const submitPrompt = ref(false);
    const confirmPrompt = ref(false);
    const insufficientPrompt = ref(false);
    const serviceInfo = useServiceSettings(ServiceNames.Highlight);

    const iconName = computed(ifElse(
      () => profile.value.gender === Sex.Male,
      () => IconName.Male,
      () => IconName.Female,
    ));

    const city = ref<ICity>(profile.value.city);

    const isSelectTheme = ref(false);

    const themes = [
      StoryTheme.Orange,
      StoryTheme.Blue,
      StoryTheme.Purple,
      StoryTheme.Cyan,
      StoryTheme.Green,
      StoryTheme.Yellow,
      StoryTheme.Grey,
    ];

    const textColors = [
      StoryTextColor.White,
      StoryTextColor.Black,
      StoryTextColor.Endeavour,
      StoryTextColor.Green,
      StoryTextColor.Supernova,
      StoryTextColor.Japonica,
      StoryTextColor.HotPink,
      StoryTextColor.Red,
      StoryTextColor.Violet,
    ];

    const handleUploadPhoto = (e: InputEvent) => {
      if ((e.target as any)?.files && (e.target as any)?.files.length) {
        photo.value = URL.createObjectURL((e.target as any)?.files[0]);
      }
    };

    const handleReady = () => {
      isSelectTheme.value = false;
    };

    const height = ref(5);

    const handleInput = (e: InputEvent) => {
      const el: HTMLElement = e.target as never;

      if (el) {
        height.value = 5;
        height.value = el.scrollHeight;
      }
    };

    const aligns = {
      [TextAlign.Left]: IconName.TextLeft,
      [TextAlign.Center]: IconName.TextCenter,
      [TextAlign.Right]: IconName.TextRight,
    };

    const handleChangeAlign = () => {
      if (align.value === TextAlign.Left) {
        align.value = TextAlign.Center;
      } else if (align.value === TextAlign.Right) {
        align.value = TextAlign.Left;
      } else if (align.value === TextAlign.Center) {
        align.value = TextAlign.Right;
      }
    };

    const isNextStep = ref(false);

    const sex = ref(profile.value.oppositeGender);
    const age = ref([usersFilterValueDefaults.age.from, usersFilterValueDefaults.age.to]);
    const hideFrom = ref(false);

    const publicStory = useGenerateStoriesAction(StoriesAction.PublicStory);

    const { showError } = useApiErrors();

    const handlePublic = async () => {
      if (profile.value) {
        const story = {
          content: text.value,
          appearance: {
            theme: theme.value,
            text_align: align.value,
            text_color: textColor.value,
          },
          audience: {
            city: city.value,
            gender: sex.value,
            age: age.value,
            hide_from_contacts: hideFrom.value,
          },
        };

        try {
          await publicStory(story);
        } catch (e) {
          showError(e);
        }
      }

      emit('close');
    };

    function goToNextStep() {
      if (!text.value) {
        textareaRef.value?.focus();
        return;
      }
      isNextStep.value = true;
    }

    function checkBalance() {
      if (serviceInfo.value.isBought || profile.value.balance >= serviceInfo.value.price) {
        handlePublic();
      } else {
        insufficientPrompt.value = true;
      }
    }

    return {
      cities,

      textareaRef,
      goToNextStep,

      submitPrompt,
      insufficientPrompt,
      confirmPrompt,
      serviceInfo,
      checkBalance,

      iconName,
      textColor,
      textColors,
      city,
      sex,
      age,
      handlePublic,
      hideFrom,
      handleChangeAlign,
      aligns,
      height,
      themes,
      isSelectTheme,
      handleReady,
      handleInput,
      theme,
      photo,
      align,
      profile,
      text,
      isNextStep,

      handleUploadPhoto,

      IconType,
      IconName,
      Size,
      MainColor,
      TxtType,
      TxtWeight,
      BtnType,
      Sex,
      showExtraFields: `${process.env.VUE_APP_HIGHLIGHT_AUDIENCE_EXTRA_FILTERS}` === 'true',
    };
  },
});

export default CreateStory;
