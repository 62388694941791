import { onBeforeUnmount, onMounted, Ref } from 'vue';

// hide keyboard after user scroll
export function useKeyboardScroll(inputRef: Ref<HTMLElement | undefined | null>) {
  let count = 0;
  let lastScroll = -1;
  let isKeyboardOpen = false;
  const scrollHandler = () => {
    if (document.activeElement === inputRef.value && isKeyboardOpen) {
      count++;
      if (count > 1 && lastScroll > window.scrollY) {
        inputRef.value?.blur();
        lastScroll = -1;
        count = 0;
      }
      lastScroll = window.scrollY;
      return;
    }

    lastScroll = -1;
    count = 0;
  };
  const resizeHandler = () => {
    isKeyboardOpen = !!window.scrollY;
  };

  onMounted(() => {
    window.visualViewport?.addEventListener('resize', resizeHandler);
    window.addEventListener('scroll', scrollHandler);
  });

  onBeforeUnmount(() => {
    window.visualViewport?.removeEventListener('resize', resizeHandler);
    window.removeEventListener('scroll', scrollHandler);
  });
}
