import { IStoryUser } from 'api/stories/models/StoryUser/interfaces/IStoryUser';
import { FileSource } from 'core/types';
import { Sex } from 'api/users/models/user';

export class StoryUser implements IStoryUser {
  id: number;
  age: number;
  name: string;
  gender: Sex;
  photos: FileSource[];

  constructor(raw: IStoryUser) {
    this.id = raw.id;
    this.age = raw.age;
    this.name = raw.name;
    this.gender = raw.gender;
    this.photos = raw.photos ?? [];
  }
}
